import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

export const NotFound: FunctionComponent = () => {
    const { push } = useHistory();

    return (
        <div className="NotFound">
            <p>К сожалению, такой страницы не существует</p>
            <p>
                <button type="button" onClick={() => push('/')}>
                    На главную
                </button>
            </p>
        </div>
    );
};
