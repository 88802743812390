import React, { FunctionComponent, useState } from 'react';

import {
    Button,
    FormControl,
    Menu,
    MenuItem,
    MenuProps,
    Select,
    TextField,
    Tooltip,
} from '@material-ui/core';
import { Done, Visibility, VisibilityOff } from '@material-ui/icons';

import { BackendProp, BackendPropLiterals, FontSize } from '../../types/editor';
import { LabelTemplateType, LabelTemplateTypeOption } from '../../types/labels';

import styles from './EditorControls.module.scss';

import { ReactComponent as AttachFile } from '../../assets/attach-file.svg';
import { ReactComponent as Literal } from '../../assets/font-size.svg';
import { ReactComponent as Sorting } from '../../assets/sorting.svg';
import { ReactComponent as UpDown } from '../../assets/up-down.svg';
import { ReactComponent as Print } from '../../assets/print.svg';

const SelectMenuOptions: Partial<MenuProps> = {
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
};

type EditorControlsProps = {
    fontSize: FontSize;
    fontSizeList: FontSize[];
    onFontSizeChange: (size: FontSize) => void;
    backendProps: BackendProp[];
    usedBackedProps: BackendPropLiterals[];
    appendText: () => void;
    appendImage: () => void;
    appendBackendProp: (prop: BackendProp) => void;
    onPrint: () => void;
    onSave: () => void;
    onCancel: () => void;
    labelNameValue: string;
    onLabelNameChange: (newLabelName: string) => void;
    templateInputRef: React.RefObject<HTMLInputElement>;
    isPreviewEnabled: boolean;
    onTogglePreview: () => void;
    currentType: LabelTemplateType | undefined;
    typeList: LabelTemplateTypeOption[];
    onChangeType: (type: LabelTemplateType) => void;
    typeInputRef: React.RefObject<HTMLInputElement>;
};

export const EditorControls: FunctionComponent<EditorControlsProps> = (props) => {
    const {
        fontSize,
        fontSizeList,
        onFontSizeChange,
        backendProps,
        usedBackedProps,
        appendBackendProp,
        appendImage,
        appendText,
        onPrint,
        onCancel,
        onSave,
        labelNameValue,
        onLabelNameChange,
        templateInputRef,
        isPreviewEnabled,
        onTogglePreview,
        currentType,
        typeList,
        onChangeType,
        typeInputRef,
    } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handlePropSelect = (prop: BackendProp) => () => {
        appendBackendProp(prop);
        handleClose();
    };

    return (
        <header className={styles.root}>
            <TextField
                placeholder="Введите название"
                size="small"
                variant="outlined"
                value={labelNameValue}
                inputProps={{ ref: templateInputRef }}
                onChange={(e) => {
                    onLabelNameChange(e.target.value);
                }}
            />
            <FormControl variant="outlined" size="small">
                <Select
                    placeholder="Тип этикетки"
                    value={currentType || 'empty_value'}
                    className={styles.selectType}
                    MenuProps={SelectMenuOptions}
                    inputProps={{ ref: typeInputRef }}
                    displayEmpty
                    onChange={(e) => {
                        e.persist();
                        onChangeType(e.target.value as LabelTemplateType);
                    }}
                >
                    <MenuItem disabled value="empty_value">
                        Тип этикетки
                    </MenuItem>
                    {typeList.map(({ label, value }) => (
                        <MenuItem key={`type-option-${value}`} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className={styles.controlGroup}>
                <div className={styles.controlGroup__item}>
                    <Tooltip title="Добавить текстовый блок">
                        <Button size="large" className={styles.controlIcon} onClick={appendText}>
                            <Literal />
                        </Button>
                    </Tooltip>
                    <FormControl variant="outlined" size="small">
                        <Select
                            value={fontSize}
                            IconComponent={UpDown}
                            classes={{ icon: styles.selectIcon }}
                            MenuProps={SelectMenuOptions}
                            onChange={(e) => {
                                e.persist();
                                onFontSizeChange(e.target.value as FontSize);
                            }}
                        >
                            {fontSizeList.map((size) => (
                                <MenuItem key={`font-size-option-${size}`} value={size}>
                                    {size}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className={styles.controlGroup__item}>
                    <Tooltip title="Добавить блок изображения">
                        <Button size="large" onClick={appendImage}>
                            <AttachFile />
                        </Button>
                    </Tooltip>
                </div>
                <div className={styles.controlGroup__item}>
                    <Tooltip title="Добавить блок привязки данных">
                        <Button
                            size="large"
                            aria-haspopup="true"
                            aria-controls="backend-props"
                            onClick={handleClick}
                        >
                            <Sorting style={{ marginRight: '0.5rem' }} />
                            <UpDown />
                        </Button>
                    </Tooltip>
                    <Menu
                        id="backend-props"
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        {backendProps.map((prop) => (
                            <MenuItem
                                key={`prop-${prop.name}`}
                                onClick={handlePropSelect(prop)}
                                disabled={usedBackedProps.includes(prop.kind)}
                            >
                                {prop.name}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </div>
            <Tooltip title={isPreviewEnabled ? 'Отключить предпросмотр' : 'Включить предпросмотр'}>
                <Button variant="outlined" className={styles.printIcon} onClick={onTogglePreview}>
                    {isPreviewEnabled ? <VisibilityOff /> : <Visibility />}
                </Button>
            </Tooltip>
            <Tooltip title="Пробная печать">
                <Button variant="outlined" className={styles.printIcon} onClick={onPrint}>
                    <Print />
                </Button>
            </Tooltip>
            <div className={styles.actionGroup}>
                <Button variant="outlined" color="primary" startIcon={<Done />} onClick={onSave}>
                    Сохранить
                </Button>
                <Button variant="outlined" onClick={onCancel}>
                    Отменить
                </Button>
            </div>
        </header>
    );
};
