import React, { FunctionComponent, RefObject } from 'react';
import { CircularProgress, Paper } from '@material-ui/core';

import { Size } from '../../types/editor';
import { WorkspaceContext } from '../../providers/WorkspaceProvider';

import styles from './EditorWorkspace.module.scss';

type EditorWorkspaceProps = {
    nodes: JSX.Element[];
    size: Size;
    workspaceRef: RefObject<HTMLDivElement>;
    isLoading?: boolean;
};

export const EditorWorkspace: FunctionComponent<EditorWorkspaceProps> = (props) => {
    const { nodes, size, workspaceRef, isLoading = false } = props;

    const padding = 2;

    return (
        <WorkspaceContext.Provider
            value={{
                padding,
                size: { width: size.width - padding * 2, height: size.height - padding * 2 },
            }}
        >
            <div className={styles.root}>
                <Paper className={styles.paper}>
                    <div
                        ref={workspaceRef}
                        className={styles.workspaceContainer}
                        style={{ width: size.width, height: size.height, padding }}
                    >
                        {isLoading ? (
                            <CircularProgress size={96} className={styles.loadingSpinner} />
                        ) : (
                            nodes
                        )}
                    </div>
                </Paper>
            </div>
        </WorkspaceContext.Provider>
    );
};
