/// <reference types="react-scripts" />
declare namespace Tag {
    const OpaqueTagSymbol: unique symbol;

    class OpaqueTag<S extends symbol> {
        private [OpaqueTagSymbol]: S;
    }

    export type OpaqueType<T, S extends symbol> = T & OpaqueTag<S>;
}

declare const GUIDTag: unique symbol;

declare global {
    type Opaque<T, S extends symbol> = Tag.OpaqueType<T, S>;
    type SelectOption<T> = { value: T; label: string };
    type GUID = Opaque<string, typeof GUIDTag>;
    type KindOf<S, T, K extends S> = T & { kind: K };
    type AtLeastOne<T, Keys extends keyof T = keyof T> = Partial<T> &
        { [K in Keys]: Required<Pick<T, K>> }[Keys];
    type PartialLike<T, Keys extends keyof T = keyof T> = Partial<T> & Omit<T, Keys>;
    type PaginatorPayload = { page?: number };
    type Paginator = { total: number; page_size: number; page: number };
    type PaginateAsList<V> = Paginator & { items: V[] };
    type PaginateAsCacheList<V, K extends string = string> = Paginator & {
        items: Record<K, V[]>;
    };
    type ReplaceDeep<Type, Path extends string, With> = Path extends `${infer Key}.${infer Rest}`
        ? Key extends keyof Type
            ? Omit<Type, Key> & { [K in Key]: ReplaceDeep<Type[Key], Rest, With> }
            : Omit<Type, Key> & { [K in Key]: With }
        : Omit<Type, Path> & { [K in Path]: With };
}

export {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StaticMeta = <T extends new (...args: any[]) => void>(): ((c: T) => void) => (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _: T
): void => {};
