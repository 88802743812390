import React, { FunctionComponent, ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { AppHeader } from '../../components/AppHeader/AppHeader';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { HeaderProps } from '../../types/layout';

import styles from './CommonLayout.module.scss';

type CommonLayoutProps = {
    children: ReactNode;
    header: HeaderProps;
    withSidebar?: boolean;
    withoutContentStyles?: boolean;
};

export const CommonLayout: FunctionComponent<CommonLayoutProps> = (props) => {
    const { children, header, withSidebar, withoutContentStyles } = props;

    return (
        <div className={styles.root}>
            <AppHeader {...header} />

            <main className={styles.main}>
                {withSidebar && <Sidebar />}

                {withoutContentStyles ? (
                    <>
                        <Typography className={styles.heading} variant="h1">
                            {header.heading}
                        </Typography>

                        {children}
                    </>
                ) : (
                    <div className={clsx(styles.content, styles.withSidebar)}>
                        <Typography className={styles.heading} variant="h1">
                            {header.heading}
                        </Typography>

                        {children}
                    </div>
                )}
            </main>
        </div>
    );
};
