import html2canvas from 'html2canvas';

import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';

import { Size } from '../types/editor';

class ReaderError extends Error {}

const fileReader = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;

        reader.readAsDataURL(file);
    });

const imageSizeReader = (base64: string): Promise<Size> =>
    new Promise((resolve, reject) => {
        const image = new Image();

        image.onload = () => resolve({ width: image.width, height: image.height });
        image.onerror = reject;

        image.src = base64;
    });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const processFirstFileTE = (files: FileList | null) => {
    const program = pipe(
        TE.Do,
        TE.bind('file', () =>
            pipe(
                O.fromNullable(files?.item(0)),
                TE.fromOption(() => new ReaderError('Пустой список файлов'))
            )
        ),
        TE.bind('blob', ({ file }) =>
            TE.tryCatch(
                () => fileReader(file),
                (e) => new ReaderError(`Ошибка преобразования файла ${e}`)
            )
        ),
        TE.bind('size', ({ blob }) =>
            TE.tryCatch(
                () => imageSizeReader(blob),
                (e) => new ReaderError(`Ошибка получить размер изображения ${e}`)
            )
        )
    );

    return program();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeImageFromEditorWorkspace = (el: HTMLElement) => {
    const program = pipe(
        TE.Do,
        TE.bind('canvas', () =>
            TE.tryCatch(
                () => html2canvas(el),
                () => new ReaderError('Не удалось создать элемент картинки')
            )
        ),
        TE.bind('image', ({ canvas }) => TE.fromIO(() => canvas.toDataURL('image/jpg', 1)))
    );

    return program();
};
