import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useStore } from 'effector-react';

import { apiClient } from './api';
import { $app } from './store/app';
import { Path, PrivateRoute } from './router';

import { FeaturedLayout } from './layouts/FeaturedLayout/FeaturedLayout';
import { LabelTemplates } from './views/LabelTemplates/LabelTemplates';
import { LabelEditor } from './views/LabelEditor/LabelEditor';
import { NotFound } from './views/NotFound/NotFound';
import { Login } from './views/Login/Login';

const RootPath = process.env.PUBLIC_URL ?? '/';

export const App: React.FunctionComponent = () => {
    const { isLoggedIn, token } = useStore($app);

    if (isLoggedIn && token !== null) {
        apiClient.setToken(token);
    }

    return (
        <Router basename={RootPath}>
            <Switch>
                <Route path={Path.Home} exact>
                    {isLoggedIn ? (
                        <Redirect to={Path.LabelTemplates} />
                    ) : (
                        <Redirect to={Path.Login} />
                    )}
                </Route>

                <PrivateRoute path={Path.LabelTemplates} exact>
                    <LabelTemplates />
                </PrivateRoute>

                <PrivateRoute path={[Path.LabelCreate, Path.LabelEdit]} exact>
                    <LabelEditor />
                </PrivateRoute>

                <Route path={Path.Login} exact>
                    <FeaturedLayout>
                        <Login />
                    </FeaturedLayout>
                </Route>

                {/* Fallback 404 */}
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
};
