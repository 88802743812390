import React, { AnimationEvent, FunctionComponent, MouseEvent, useState } from 'react';
import clsx from 'clsx';
import { useStore } from 'effector-react';
import { useHistory } from 'react-router-dom';

import {
    Button,
    CircularProgress,
    FilledInput,
    FormControl,
    IconButton,
    InputLabel,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { Protocol } from '../../api/protocol';
import { loginFx } from '../../store/app';
import { AutofillEvents } from '../../utils/const';
import { useNoty } from '../../hooks/noty';
import { Path } from '../../router';

import styles from './Login.module.scss';

type FormData = {
    login: string;
    password: string;
};
export const Login: FunctionComponent = () => {
    const [isShowPassword, setIsShowPassword] = React.useState(false);
    const [autofilledValues, setAutofilledValues] = React.useState({
        password: false,
        login: false,
    });
    const [formData, setFormData] = useState<FormData>({
        login: '',
        password: '',
    });

    const isLoading = useStore(loginFx.pending);
    const { handleApiError, showNoty } = useNoty();
    const isSubmitDisabled = formData.login === '' || formData.password === '';
    const history = useHistory();

    const handleAutofill = (event: AnimationEvent<HTMLDivElement>, field: 'password' | 'login') => {
        if (
            event.animationName !== AutofillEvents.start &&
            event.animationName !== AutofillEvents.end
        ) {
            return;
        }
        const isAutofilled = event.animationName === AutofillEvents.start;

        setAutofilledValues({ ...autofilledValues, [field]: isAutofilled });
    };

    const handleClickShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    };

    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
    };

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (isSubmitDisabled || isLoading) return;
        const { login, password } = formData;

        const payload: Protocol.LoginPayload = {
            login,
            password,
        };

        loginFx(payload)
            .then(() => {
                showNoty('Вы успешно вошли в приложение', 'success');
                history.push(Path.Home);
            })
            .catch(handleApiError);
    };

    return (
        <form className={styles.root} onSubmit={handleFormSubmit}>
            <div className={styles.controls}>
                <FormControl
                    variant="filled"
                    className={clsx(styles.control, autofilledValues.login && styles.autofilled)}
                >
                    <FilledInput
                        disableUnderline
                        id="login"
                        type="text"
                        value={formData.login}
                        onAnimationStart={(e) => handleAutofill(e, 'login')}
                        onChange={(event) => {
                            setFormData((prevState) => ({
                                ...prevState,
                                login: event.target.value,
                            }));
                        }}
                        inputProps={{
                            className: styles.input,
                        }}
                    />
                    <InputLabel className={styles.label} htmlFor="login">
                        Логин
                    </InputLabel>
                </FormControl>

                <FormControl
                    variant="filled"
                    className={clsx(styles.control, autofilledValues.password && styles.autofilled)}
                >
                    <FilledInput
                        disableUnderline
                        id="password"
                        value={formData.password}
                        onChange={(event) => {
                            setFormData((prevState) => ({
                                ...prevState,
                                password: event.target.value,
                            }));
                        }}
                        type={isShowPassword ? 'text' : 'password'}
                        onAnimationStart={(e) => handleAutofill(e, 'password')}
                        inputProps={{
                            className: clsx(styles.input, styles.inputWithIcon),
                        }}
                    />

                    <InputLabel className={styles.label} htmlFor="password">
                        Пароль
                    </InputLabel>

                    <IconButton
                        className={styles.iconButton}
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                    >
                        {isShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </FormControl>
            </div>

            <Button
                type="submit"
                variant="contained"
                disabled={isSubmitDisabled}
                className={styles.button}
                color="primary"
            >
                {isLoading ? <CircularProgress color="inherit" size={22} /> : 'Войти'}
            </Button>
        </form>
    );
};
