import EventEmitter from 'events';
import { useEffect } from 'react';

type WorkspaceEventsType = 'beforePrint' | 'afterPrint';

interface WorkspaceEventsInterface {
    emit: (name: WorkspaceEventsType) => void;
    subscribe: (name: WorkspaceEventsType, listener: () => void) => void;
    unsubscribe: (name: WorkspaceEventsType, listener: () => void) => void;
}

const WorkspaceEventsEmitter = new EventEmitter();

export const WorkspaceEvents: WorkspaceEventsInterface = {
    emit: WorkspaceEventsEmitter.emit,
    subscribe: WorkspaceEventsEmitter.addListener,
    unsubscribe: WorkspaceEventsEmitter.removeListener,
};

export function useWorkspaceEvent(name: WorkspaceEventsType, listener: () => void): void {
    useEffect(() => {
        WorkspaceEvents.subscribe(name, listener);
        return () => WorkspaceEvents.unsubscribe(name, listener);
    }, [name, listener]);
}
