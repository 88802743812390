import React, { CSSProperties, RefObject } from 'react';
import { nanoid } from 'nanoid';
import clsx from 'clsx';

import { ClickAwayListener } from '@material-ui/core';
import { Crop, DeleteForever } from '@material-ui/icons';

import {
    AbstractEditorNode,
    DehydrateBindings,
    EditorNodeInterface,
    EditorNodeMetadata,
    EditorNodePosition,
    NodeProps,
} from './interface/node.interface';
import { BackendProp, FontSize, fontSizeList } from '../../types/editor';

import { StaticMeta } from '../../utils/types';
import { WorkspaceEvents } from '../../utils/workspace';
import { isCodeProp } from '../../utils/view';

import baseStyles from './BaseNode.module.scss';
import styles from './BackendPropNode.module.scss';

import TestBarcode from '../../assets/test-barcode.png';
import TestQRCode from '../../assets/test-qr-code.png';

type BackendPropNodeProps = NodeProps & {
    fontSize: FontSize;
    prop: BackendProp;
};

type BackendPropNodeState = {
    position: EditorNodePosition;
    fontSize: FontSize;
    propTextState: string;
};

const DefaultViewState = 'N/A';

const getBackendPropSchematicView = (prop: BackendProp): string => {
    if (!isCodeProp(prop)) return prop.schematicView;
    return DefaultViewState;
};

const getBackendPropSchematicPreview = (prop: BackendProp): string => {
    if (!isCodeProp(prop)) return prop.schematicPreview;
    return DefaultViewState;
};

const getTestCodeCSSProp = (prop: BackendProp): CSSProperties['backgroundImage'] => {
    switch (prop.kind) {
        case 'barcode':
            return `url(${TestBarcode})`;
        case 'qr_code':
            return `url(${TestQRCode})`;
        default:
            return 'none';
    }
};

@StaticMeta<EditorNodeInterface>()
export class BackendPropNode extends AbstractEditorNode<
    BackendPropNodeProps,
    BackendPropNodeState
> {
    constructor(props: BackendPropNodeProps) {
        super(props);

        const { position } = this.props;

        this.state = {
            position,
            selected: false,
            dragging: false,
            draggable: false,
            editable: false,
            resizable: false,
            size: props.size,
            fontSize: props.fontSize,
            propTextState: getBackendPropSchematicView(props.prop),
        };
    }

    static dehydrate(
        meta: EditorNodeMetadata,
        selfRef: RefObject<AbstractEditorNode>
    ): DehydrateBindings {
        if (meta.kind !== 'backendProp')
            throw new TypeError('Invalid meta type, expected Backend Prop meta');

        return (onDelete) => (
            <BackendPropNode
                ref={selfRef as RefObject<BackendPropNode>}
                key={nanoid(6)}
                size={meta.size}
                position={meta.position}
                fontSize={meta.content.fontSize}
                prop={meta.content.prop}
                onDelete={onDelete}
            />
        );
    }

    hydrate = (): EditorNodeMetadata => {
        const { position, size, fontSize } = this.state;
        const { prop } = this.props;

        return {
            kind: 'backendProp',
            content: { prop, fontSize },
            position,
            size,
        };
    };

    beforeStartPrint = (): void => {
        this.clickAwayHandler();
        this.setState({ propTextState: getBackendPropSchematicPreview(this.props.prop) });
    };

    afterStartPrint = (): void => {
        this.setState({ propTextState: getBackendPropSchematicView(this.props.prop) });
    };

    componentDidMount(): void {
        WorkspaceEvents.subscribe('beforePrint', this.beforeStartPrint);
        WorkspaceEvents.subscribe('afterPrint', this.afterStartPrint);
    }

    componentWillUnmount(): void {
        WorkspaceEvents.unsubscribe('beforePrint', this.beforeStartPrint);
        WorkspaceEvents.unsubscribe('afterPrint', this.afterStartPrint);
    }

    onChangeHandler = (): void => {};

    onEditHandler = (): void => {};

    onSaveHandler = (): void => {};

    onDeleteHandler = (): void => this.props.onDelete();

    render(): JSX.Element {
        const {
            position,
            selected,
            resizable,
            dragging,
            draggable,
            editable,
            size,
            propTextState,
        } = this.state;
        const { prop } = this.props;

        return (
            <ClickAwayListener onClickAway={this.clickAwayHandler}>
                <div
                    aria-hidden="true"
                    ref={this.blockRef}
                    style={{
                        top: position.y,
                        left: position.x,
                        fontSize: this.state.fontSize,
                        width: size.width,
                        height: size.height,
                        backgroundImage: getTestCodeCSSProp(prop),
                    }}
                    onClick={this.toggleSelected}
                    onMouseDown={resizable ? undefined : this.mouseDownHandler}
                    className={clsx(
                        baseStyles.root,
                        selected && baseStyles.selected,
                        dragging && baseStyles.dragging,
                        resizable && baseStyles.resizable,
                        !editable && draggable && baseStyles.draggable,
                        isCodeProp(prop) ? styles.barcodeRoot : styles.textRoot
                    )}
                >
                    {!isCodeProp(prop) && <div>{propTextState}</div>}
                    <div
                        className={baseStyles.menuContainer}
                        style={{
                            bottom: size.height - 1,
                            display: selected && !resizable ? 'flex' : 'none',
                        }}
                    >
                        {/* --- Font size select --- */}
                        {!isCodeProp(prop) && (
                            <select
                                className={baseStyles.fontSizeSelect}
                                value={this.state.fontSize}
                                onChange={(event) => {
                                    this.setState({
                                        fontSize: Number(event.currentTarget.value) as FontSize,
                                    });
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {fontSizeList.map((listItem) => (
                                    <option key={listItem} value={listItem}>
                                        {listItem}
                                    </option>
                                ))}
                            </select>
                        )}
                        <button
                            type="button"
                            className={baseStyles.menuButton}
                            onClick={this.onResizeHandler}
                        >
                            <Crop fontSize="small" />
                        </button>

                        <button
                            type="button"
                            className={baseStyles.menuButton}
                            onClick={this.onDeleteHandler}
                        >
                            <DeleteForever fontSize="small" />
                        </button>
                    </div>
                    {resizable && (
                        <div
                            aria-hidden="true"
                            className={baseStyles.resizableArea}
                            onMouseDown={this.resizeMouseDownHandler}
                        />
                    )}
                </div>
            </ClickAwayListener>
        );
    }
}
