export * from './PrivateRoute';

export const Path = {
    Home: '/',
    Login: '/login',
    LabelCreate: '/label-editor/create',
    LabelTemplates: '/label-templates',
    LabelEdit: '/label-editor/:id',
};

type RouteParamMap = Record<string, string | number>;

export function pathWithParam(path: string, paramMap: RouteParamMap): string {
    const key = Object.keys(paramMap)[0];
    const value = Object.values(paramMap)[0];

    return path.replace(new RegExp(`:${key}`, 'g'), value.toString());
}
