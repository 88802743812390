import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { LabelsTable } from '../../components/LabelsTable/LabelsTable';
import { CommonLayout } from '../../layouts/CommonLayout/CommonLayout';
import { fetchTemplateListFx } from '../../store/templates';
import { useNoty } from '../../hooks/noty';
import { Path } from '../../router';

import styles from './LabelTemplates.module.scss';

export const LabelTemplates: FunctionComponent = () => {
    const { handleApiError } = useNoty();
    const history = useHistory();

    useEffect(() => {
        fetchTemplateListFx().catch(handleApiError);
    }, [handleApiError]);

    return (
        <CommonLayout
            header={{
                heading: 'Шаблоны этикеток',
                button: {
                    text: 'Новый шаблон',
                    action: () => {
                        history.push(Path.LabelCreate);
                    },
                },
            }}
        >
            <div className={styles.root}>
                <LabelsTable />
            </div>
        </CommonLayout>
    );
};
