import React, { FC } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import styles from './AlertDialog.module.scss';

type Props = {
    onClose: () => void;
    onConfirm: () => void;
    isOpen: boolean;
    title: string;
    content: string;
    confirmCaption?: string;
    cancelCaption?: string;
    isLoading?: boolean;
};
export const AlertDialog: FC<Props> = (props) => {
    const {
        isOpen,
        onClose,
        onConfirm,
        title,
        content,
        confirmCaption = 'Подтвердить',
        cancelCaption = 'Отмена',
        isLoading = false,
    } = props;

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle className={styles.dialogTitle} disableTypography>
                <Typography variant="subtitle1">{title}</Typography>
                <IconButton className={styles.closeButton} onClick={onClose} size="small">
                    <Close />
                </IconButton>
            </DialogTitle>

            <DialogContent className={styles.dialogContent}>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>

            <DialogActions className={styles.dialogActions}>
                <Button
                    onClick={onConfirm}
                    variant="text"
                    color="secondary"
                    autoFocus
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <CircularProgress color="inherit" size={23} />
                    ) : (
                        <span>{confirmCaption}</span>
                    )}
                </Button>

                <Button onClick={onClose} variant="text" color="primary">
                    {cancelCaption}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
