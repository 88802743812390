import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'effector-react';

import clsx from 'clsx';

import { flow, pipe } from 'fp-ts/function';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as Ord from 'fp-ts/Ord';
import * as Eq from 'fp-ts/Eq';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';

import {
    Button,
    Checkbox,
    CircularProgress,
    Fab,
    Paper,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { LabelTemplate, LabelTemplateType, LabelVessels, Vessel } from '../../types/labels';
import { labelTemplateTypeMap } from '../../utils/const';
import { Path, pathWithParam } from '../../router';
import { Protocol } from '../../api/protocol';
import { useNoty } from '../../hooks/noty';
import {
    $labelTemplates,
    assignTemplatesToVesselsFx,
    copyLabelTemplateFx,
    deleteLabelTemplateFx,
} from '../../store/templates';

import { TableCreateType } from '../TableCreateType/TableCreateType';
import { TableEmptyState } from '../TableEmptyState/TableEmptyState';
import { AlertDialog } from '../AlertDialog/AlertDialog';

import styles from './LabelsTable.module.scss';

type NELabelVessels = ReplaceDeep<LabelVessels, 'vessel_ids', NEA.NonEmptyArray<number>>;
type AlmostCheckedVesselId = {
    vesselId: number;
    templateId: number;
};

type LabelsTableRowProps = {
    type: LabelTemplateType;
    vessels: Vessel[];
    template: LabelTemplate;
    checkedVesselIds: number[];
    almostCheckedVesselIds: AlmostCheckedVesselId[];
    isCopying: boolean;
    onCopyClick: (id: LabelTemplate) => void;
    onDeleteClick: (id: number) => void;
    onVesselsCheck: (labelId: number, checkedVesselIds: number[]) => void;
};
const LabelsTableRow: FunctionComponent<LabelsTableRowProps> = (props) => {
    const {
        template,
        vessels,
        onDeleteClick,
        onCopyClick,
        checkedVesselIds,
        almostCheckedVesselIds,
        onVesselsCheck,
        isCopying,
        type,
    } = props;
    const history = useHistory();

    const onVesselCheckboxClick = (vesselId: number): void => {
        const newCheckedIds = [...checkedVesselIds];
        const vesselIndex = newCheckedIds.findIndex((id) => id === vesselId);

        if (vesselIndex === -1) {
            newCheckedIds.push(vesselId);
        } else {
            newCheckedIds.splice(vesselIndex, 1);
        }

        onVesselsCheck(template.id, newCheckedIds);
    };

    const checkDisabled = (vesselId: number): boolean => {
        if (type === 'normal') return false;

        return pipe(
            almostCheckedVesselIds,
            A.findFirst(
                (almost) => almost.vesselId === vesselId && almost.templateId !== template.id
            ),
            O.isSome
        );
    };

    return (
        <TableRow>
            <TableCell className={styles.cell}>
                <div className={clsx(styles.labelWrapper, styles.cellInner)}>
                    <span>{template.name}</span>
                    <div className={styles.actionButtonsWrapper}>
                        <Button
                            variant="outlined"
                            className={styles.editButton}
                            onClick={() => {
                                history.push(pathWithParam(Path.LabelEdit, { id: template.id }));
                            }}
                        >
                            <CreateIcon />
                        </Button>
                        <Button
                            variant="outlined"
                            className={styles.editButton}
                            disabled={isCopying}
                            onClick={() => onCopyClick(template)}
                        >
                            <FileCopyIcon />
                        </Button>
                        <Button
                            className={styles.editButton}
                            variant="outlined"
                            onClick={() => {
                                onDeleteClick(template.id);
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                    </div>
                </div>
            </TableCell>

            {vessels.map((vessel) => (
                <TableCell className={styles.cell} key={vessel.id}>
                    <div className={clsx(styles.cellInner, styles.center)}>
                        <Checkbox
                            color="default"
                            checked={checkedVesselIds.includes(vessel.id) ?? false}
                            disabled={checkDisabled(vessel.id)}
                            onChange={() => onVesselCheckboxClick(vessel.id)}
                        />
                    </div>
                </TableCell>
            ))}
        </TableRow>
    );
};

type LabelsTableContentProps = {
    type: LabelTemplateType;
    list: LabelTemplate[];
    vessels: Vessel[];
    labelVessels: LabelVessels[];
    rowData: Pick<
        LabelsTableRowProps,
        'isCopying' | 'onCopyClick' | 'onDeleteClick' | 'onVesselsCheck'
    >;
};

const LabelsTableContent: FunctionComponent<LabelsTableContentProps> = (props) => {
    const { vessels, type, list, labelVessels, rowData } = props;

    const meatListIds = pipe(
        list,
        O.fromPredicate(() => type === 'meat'),
        O.map(A.map((item) => item.id)),
        O.getOrElse(() => [] as number[])
    );

    const almostCheckedVesselIds = pipe(
        labelVessels,
        A.filter(
            (item): item is NELabelVessels =>
                item.vessel_ids.length > 0 && meatListIds.includes(item.label_template_id)
        ),
        A.map((item): AlmostCheckedVesselId[] =>
            pipe(
                item.vessel_ids,
                A.map((vesselId) => ({ templateId: item.label_template_id, vesselId }))
            )
        ),
        A.flatten
    );

    const findVesselsIds = (template: LabelTemplate): number[] =>
        labelVessels.find((item) => item.label_template_id === template.id)?.vessel_ids ??
        template.vessel_ids;

    if (list.length === 0)
        return (
            <TableEmptyState
                colSpan={vessels.length + 1}
                caption="Нет сохраненных шаблонов этикеток"
            />
        );

    return (
        <>
            {list.map((template: LabelTemplate) => (
                <LabelsTableRow
                    key={template.id}
                    type={type}
                    vessels={vessels}
                    template={template}
                    checkedVesselIds={findVesselsIds(template)}
                    almostCheckedVesselIds={almostCheckedVesselIds}
                    {...rowData}
                />
            ))}
        </>
    );
};

const sortVesselIds = A.sort(Ord.ordNumber);

const foldRemoteVessels = flow(
    A.filter((template: LabelTemplate) => template.vessel_ids.length > 0),
    A.map(
        (template): LabelVessels => ({
            label_template_id: template.id,
            vessel_ids: sortVesselIds(template.vessel_ids),
        })
    )
);

const eqLabelVessel = Eq.getStructEq<LabelVessels>({
    label_template_id: Eq.eqNumber,
    vessel_ids: A.getEq(Eq.eqNumber),
});

const eqLabelVessels = A.getEq(eqLabelVessel);

export const LabelsTable: FunctionComponent = () => {
    const history = useHistory();
    const { handleApiError, showNoty, closeNoty } = useNoty();

    const [idToDelete, setIdToDelete] = useState<number | null>(null);
    const [labelVesselsAssignmentsToChange, setLabelVesselsAssignmentsToChange] = useState<
        LabelVessels[]
    >([]);

    const { vessels, items, isFetched } = useStore($labelTemplates);
    const isDeleteLoading = useStore(deleteLabelTemplateFx.pending);
    const isLoading = useStore(assignTemplatesToVesselsFx.pending);
    const isCopying = useStore(copyLabelTemplateFx.pending);

    useEffect(() => setLabelVesselsAssignmentsToChange(foldRemoteVessels(items)), [items]);
    const remoteVessels = useMemo(() => foldRemoteVessels(items), [items]);

    const isSaveDisabled = eqLabelVessels.equals(remoteVessels, labelVesselsAssignmentsToChange);

    const { left: normalAndTransportItems, right: meatItems } = pipe(
        items,
        A.partition((item: LabelTemplate) => item.type === 'meat')
    );

    const { left: normalItems, right: transportItems } = pipe(
        normalAndTransportItems,
        A.partition((item: LabelTemplate) => item.type === 'transport_package')
    );

    const handleDelete = () => {
        if (isDeleteLoading || idToDelete === null) return;

        deleteLabelTemplateFx({ id: idToDelete })
            .then(() => {
                showNoty('Шаблон удален', 'success');
                setIdToDelete(null);
            })
            .catch(handleApiError);
    };

    const handleCopy = (template: LabelTemplate): void => {
        if (isCopying) return;

        const ref = showNoty(`Копирование шаблона "${template.name}"`, 'info');

        copyLabelTemplateFx({ id: template.id })
            .then(() => showNoty(`Шаблон "${template.name}" скопирован`, 'success'))
            .catch(handleApiError)
            .finally(() => closeNoty(ref));
    };

    const onLabelVesselsChange = (labelId: number, checkedVesselIds: number[]): void => {
        setLabelVesselsAssignmentsToChange((prevState) => {
            const newState = [...prevState];
            const labelVesselToChange = newState.find((item) => item.label_template_id === labelId);

            if (labelVesselToChange !== undefined) {
                labelVesselToChange.vessel_ids = sortVesselIds(checkedVesselIds);
            } else {
                newState.push({
                    label_template_id: labelId,
                    vessel_ids: sortVesselIds(checkedVesselIds),
                });
            }

            return newState;
        });
    };

    const onSaveAssignmentChangesClick = (): void => {
        if (isLoading) return;

        const payload: Protocol.LabelAssignVesselsPayload = {
            items: labelVesselsAssignmentsToChange,
        };
        assignTemplatesToVesselsFx(payload)
            .then(() => {
                showNoty('Изменения сохранены', 'success');
            })
            .catch(handleApiError);
    };

    const onCreateHandler = (type: LabelTemplateType): void => {
        history.push(`${Path.LabelCreate}?type=${type}`);
    };

    const technicalRowSpan = vessels.length + 1;
    const rowData = {
        isCopying,
        onCopyClick: handleCopy,
        onDeleteClick: setIdToDelete,
        onVesselsCheck: onLabelVesselsChange,
    };

    return (
        <div className={styles.root}>
            <TableContainer className={styles.tableContainer} component={Paper}>
                <Table aria-label="customized table">
                    <TableHead className={styles.head}>
                        <TableRow>
                            <TableCell className={clsx(styles.cell, styles.gray)}>
                                <div className={styles.cellInner}>Шаблон</div>
                            </TableCell>

                            {vessels.map((vessel) => (
                                <TableCell className={styles.cell} key={vessel.id}>
                                    <div className={clsx(styles.cellInner, styles.center)}>
                                        {vessel.short_name ?? vessel.name}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {!isFetched && (
                            <TableRow className={styles.loaderRow}>
                                <TableCell>
                                    <div className={styles.loader}>
                                        <CircularProgress size={60} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                        {isFetched && (
                            <>
                                <TableCreateType
                                    type="meat"
                                    label={labelTemplateTypeMap.meat}
                                    colSpan={technicalRowSpan}
                                    onCreateType={onCreateHandler}
                                />
                                <LabelsTableContent
                                    type="meat"
                                    list={meatItems}
                                    rowData={rowData}
                                    vessels={vessels}
                                    labelVessels={labelVesselsAssignmentsToChange}
                                />

                                <TableCreateType
                                    type="normal"
                                    label={labelTemplateTypeMap.normal}
                                    colSpan={technicalRowSpan}
                                    onCreateType={onCreateHandler}
                                />
                                <LabelsTableContent
                                    type="normal"
                                    list={normalItems}
                                    rowData={rowData}
                                    vessels={vessels}
                                    labelVessels={labelVesselsAssignmentsToChange}
                                />
                                <TableCreateType
                                    type="transport_package"
                                    label={labelTemplateTypeMap.transport_package}
                                    colSpan={technicalRowSpan}
                                    onCreateType={onCreateHandler}
                                />
                                <LabelsTableContent
                                    type="transport_package"
                                    list={transportItems}
                                    rowData={rowData}
                                    vessels={vessels}
                                    labelVessels={labelVesselsAssignmentsToChange}
                                />
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <AlertDialog
                title="Удалить шаблон?"
                content="Шаблон будет удален без возможности восстановления"
                cancelCaption="Отмена"
                confirmCaption="Удалить"
                isOpen={idToDelete !== null}
                onClose={() => setIdToDelete(null)}
                onConfirm={handleDelete}
            />

            <Slide direction="up" mountOnEnter unmountOnExit in={!isSaveDisabled}>
                <Fab
                    color="primary"
                    variant="extended"
                    className={styles.fabSaveChanges}
                    onClick={onSaveAssignmentChangesClick}
                >
                    <CheckIcon />
                    Сохранить
                </Fab>
            </Slide>
        </div>
    );
};
