import React, { FunctionComponent } from 'react';

import { Button, TableCell, TableRow } from '@material-ui/core';

import { LabelTemplateType } from '../../types/labels';

import styles from './TableCreateType.module.scss';

type TableCreateTypeProps = {
    type: LabelTemplateType;
    label: string;
    colSpan: number;
    onCreateType: (type: LabelTemplateType) => void;
};

export const TableCreateType: FunctionComponent<TableCreateTypeProps> = (props) => {
    const { type, label, colSpan, onCreateType } = props;

    return (
        <TableRow className={styles.row}>
            <TableCell colSpan={colSpan} className={styles.col}>
                <div className={styles.root}>
                    {label}
                    <Button
                        className={styles.button}
                        variant="text"
                        onClick={() => onCreateType(type)}
                    >
                        + Добавить
                    </Button>
                </div>
            </TableCell>
        </TableRow>
    );
};
