import { FontSize, Size } from '../types/editor';

export const pluralize = (count: number, words: string[]): string => {
    const cases = [2, 0, 1, 1, 1, 2];
    return words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]];
};

export const toFixed = (value: number, fractionDigits = 2): number =>
    Number(value.toFixed(fractionDigits).replace(`.${'0'.repeat(fractionDigits)}`, ''));

export const formatNumber = (value: number, separator = ' '): string =>
    String(value)
        .replace(/(\d)(?=(\d{3})+(\.|$))/g, `$1${separator}`)
        .replace(/\./g, ',');

const symbolWidth: Record<FontSize, number> = {
    8: 400 / 62,
    10: 400 / 58,
    12: 400 / 53,
    14: 400 / 48,
    16: 400 / 40,
    18: 400 / 39,
    20: 400 / 32,
    22: 400 / 28,
    24: 400 / 26,
    26: 400 / 24,
    28: 400 / 22,
    36: 400 / 20,
    48: 400 / 14,
    72: 400 / 8,
};

export const approximateTextSize = (text: string, fontSize: FontSize): Size => {
    const widthThreshold = 550;

    const oneRowLength = text.length * symbolWidth[fontSize];
    const rowsRatio = oneRowLength / widthThreshold;

    return rowsRatio > 1
        ? {
              width: widthThreshold,
              height: Math.ceil((text.length * fontSize) / widthThreshold) * fontSize + 2,
          }
        : { width: oneRowLength, height: fontSize + 10 };
};
