import React, { FunctionComponent, ReactNode } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useStore } from 'effector-react';
import { $app } from '../store/app';

type PrivateProps = {
    redirectTo?: string;
    children: ReactNode;
    exact?: boolean;
    path: string | string[];
};

type PrivateRouteProps = PrivateProps & RouteProps;

export const PrivateRoute: FunctionComponent<PrivateRouteProps> = (props) => {
    const { path, exact = false, redirectTo = '/', children } = props;
    const { isLoggedIn } = useStore($app);

    return (
        <Route
            path={path}
            exact={exact}
            render={({ location }) =>
                isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: redirectTo,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};
