import React, { RefObject } from 'react';
import { nanoid } from 'nanoid';
import clsx from 'clsx';

import * as E from 'fp-ts/Either';

import { ClickAwayListener } from '@material-ui/core';
import { Crop, DeleteForever, Edit } from '@material-ui/icons';

import { pipe } from 'fp-ts/function';
import {
    AbstractEditorNode,
    DehydrateBindings,
    EditorNodeInterface,
    EditorNodeMetadata,
    EditorNodePosition,
    NodeProps,
} from './interface/node.interface';
import { Size } from '../../types/editor';

import { processFirstFileTE } from '../../utils/reader';
import { StaticMeta } from '../../utils/types';

import baseStyles from './BaseNode.module.scss';
import styles from './ImageNode.module.scss';

type ImageNodeProps = NodeProps & { blob: string };
type ImageNodeState = {
    position: EditorNodePosition;
    blob: string;
};

@StaticMeta<EditorNodeInterface>()
export class ImageNode extends AbstractEditorNode<ImageNodeProps, ImageNodeState> {
    constructor(props: ImageNodeProps) {
        super(props);

        const { position, size, blob } = this.props;

        this.state = {
            blob,
            size,
            position,
            selected: false,
            dragging: false,
            editable: false,
            draggable: false,
            resizable: false,
        };
    }

    static dehydrate(
        meta: EditorNodeMetadata,
        selfRef: RefObject<AbstractEditorNode>
    ): DehydrateBindings {
        if (meta.kind !== 'image') throw new TypeError('Invalid meta type, expected Image meta');

        return (onDelete) => (
            <ImageNode
                ref={selfRef as RefObject<ImageNode>}
                key={nanoid(6)}
                position={meta.position}
                size={meta.size}
                blob={meta.content.blob}
                onDelete={onDelete}
            />
        );
    }

    resizeGuard = (size: Size): Size => ({
        width: Math.min(this.context.size.width - 10, size.width),
        height: Math.min(this.context.size.height - 10, size.height),
    });

    hydrate = (): EditorNodeMetadata => {
        const { position, blob, size } = this.state;

        return {
            kind: 'image',
            position,
            size,
            content: { blob },
        };
    };

    onEditHandler = (): void => this.inputRef.current?.click();

    onChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();

        const firstFileData = await processFirstFileTE(e.target.files);

        pipe(
            firstFileData,
            E.fold(
                (readerError) => console.warn(readerError),
                ({ blob, size }) => this.setState({ blob, size, selected: true })
            )
        );
    };

    onSaveHandler = (): void => {};

    onDeleteHandler = (): void => this.props.onDelete();

    render(): JSX.Element {
        const {
            blob,
            position,
            selected,
            draggable,
            resizable,
            dragging,
            editable,
            size,
        } = this.state;

        return (
            <ClickAwayListener onClickAway={this.clickAwayHandler}>
                <div
                    aria-hidden="true"
                    ref={this.blockRef}
                    style={{
                        top: position.y,
                        left: position.x,
                        width: size.width,
                        height: size.height,
                        backgroundImage: `url(${blob})`,
                    }}
                    onClick={this.toggleSelected}
                    onMouseDown={resizable ? undefined : this.mouseDownHandler}
                    className={clsx(
                        baseStyles.root,
                        styles.root,
                        selected && baseStyles.selected,
                        dragging && baseStyles.dragging,
                        resizable && baseStyles.resizable,
                        !editable && draggable && baseStyles.draggable
                    )}
                >
                    <input
                        hidden
                        type="file"
                        accept="image/*"
                        multiple={false}
                        ref={this.inputRef}
                        onChange={this.onChangeHandler}
                    />
                    <div
                        className={baseStyles.menuContainer}
                        style={{
                            bottom: size.height - 1,
                            display: selected && !resizable ? 'flex' : 'none',
                        }}
                    >
                        <button
                            type="button"
                            className={baseStyles.menuButton}
                            onClick={this.onEditHandler}
                        >
                            <Edit fontSize="small" />
                        </button>
                        <button
                            type="button"
                            className={baseStyles.menuButton}
                            onClick={this.onResizeHandler}
                        >
                            <Crop fontSize="small" />
                        </button>
                        <button
                            type="button"
                            className={baseStyles.menuButton}
                            onClick={this.onDeleteHandler}
                        >
                            <DeleteForever fontSize="small" />
                        </button>
                    </div>
                    {resizable && (
                        <div
                            aria-hidden="true"
                            className={baseStyles.resizableArea}
                            onMouseDown={this.resizeMouseDownHandler}
                        />
                    )}
                </div>
            </ClickAwayListener>
        );
    }
}
