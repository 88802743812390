import React from 'react';
import { SnackbarProvider } from 'notistack';
import {
    SnackbarOrigin,
    unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { ruRU } from '@material-ui/core/locale';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { App } from './App';

// must be manually synced with breakpoints in styles/variables.scss
const breakpoints = createBreakpoints({
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
    },
});

const theme = createMuiTheme(
    {
        palette: {
            primary: {
                main: '#6200EE',
            },
            secondary: {
                main: '#EB5757',
            },
        },
        overrides: {
            MuiTableContainer: {
                root: {
                    boxShadow: 'none',
                },
            },
            MuiTableHead: {
                root: {
                    backgroundColor: '#fdfdfd',
                },
            },
            MuiTableCell: {
                root: {
                    borderBottom: 'none',
                },
            },
            MuiTableRow: {
                root: {
                    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
                },
            },
            MuiTypography: {
                h1: {
                    fontSize: 20,
                    fontWeight: 500,
                },
            },
            MuiButton: {
                root: {
                    color: '#666666',
                    textTransform: 'none',

                    '&$disabled': {
                        cursor: 'not-allowed',
                    },
                },
                contained: {
                    boxShadow: 'none',
                    fontWeight: 500,
                    fontSize: 14,
                    paddingTop: 10,
                    lineHeight: 1.6,
                    paddingBottom: 10,
                    letterSpacing: '1.25px',

                    '&:hover, &:focus, &:active': {
                        boxShadow: 'none',
                    },
                },
                containedPrimary: {
                    $disabled: {
                        color: 'rgba(255, 255, 255, 0.6)',
                        backgroundColor: 'rgba(194, 194, 194, 0.05)',
                    },
                },
                outlinedPrimary: {
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                },
                outlined: {
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                },
            },
            MuiIconButton: {
                root: {
                    padding: 16,
                    color: 'white',
                    transition: 'color 0.25s ease-in-out',

                    '&:hover': {
                        color: '#6200EE',
                    },
                },
            },
            MuiFilledInput: {
                root: {
                    backgroundColor: '#343a46',
                    borderColor: '#343a46',
                    color: 'white',
                    borderRadius: '4px',
                },
                input: {
                    borderRadius: '4px',
                },
            },
            MuiInputLabel: {
                filled: {
                    color: '#FFFFFF99',
                },
            },
            MuiCheckbox: {
                root: {
                    color: 'rgba(0, 0, 0, 0.87)',
                },
            },
            MuiFab: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        breakpoints,
    },
    ruRU
);

// ---- Root component  ----
export const Root: React.FunctionComponent = () => {
    const snackbarAnchorOptions: SnackbarOrigin = {
        vertical: 'top',
        horizontal: 'right',
    };

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider anchorOrigin={snackbarAnchorOptions}>
                <StylesProvider injectFirst>
                    <App />
                </StylesProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
};
