import React, { FC } from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import ViewListRoundedIcon from '@material-ui/icons/ViewListRounded';

import styles from './TableEmptyState.module.scss';

type Props = {
    colSpan: number;
    caption: string;
};
export const TableEmptyState: FC<Props> = ({ colSpan, caption }) => (
    <TableRow>
        <TableCell colSpan={colSpan}>
            <div className={styles.root}>
                <div className={styles.content}>
                    <ViewListRoundedIcon />
                    <Typography variant="h6">{caption}</Typography>
                </div>
            </div>
        </TableCell>
    </TableRow>
);
