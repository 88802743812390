import { onlyNumbers } from './view';
import { LabelTemplateTypeMap } from '../types/labels';

export enum Masks {
    Code = '9999',
    Phone = '+7 (999) 999-99-99',
}

export const PhoneRawLength = onlyNumbers(Masks.Phone).length;
export const PhoneLength = Masks.Phone.length;
export const CodeLength = Masks.Code.length;

export enum DateTimeFormats {
    ApiDate = 'YYYY.MM.DD',
    ApiDatetime = 'YYYY-MM-DDTHH:mm:ssZ',
}

export const AutofillEvents = {
    start: 'mui-auto-fill',
    end: 'mui-auto-fill-cancel',
};

export const labelTemplateTypeMap: LabelTemplateTypeMap = {
    meat: 'Мясо',
    normal: 'Конечности, абдомен',
    transport_package: 'Транспортная упаковка',
};
