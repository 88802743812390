import React, {
    createRef,
    FunctionComponent,
    RefObject,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useStore } from 'effector-react';
import { useHistory, useParams } from 'react-router-dom';

import { Lens } from 'monocle-ts';

import { flow, pipe } from 'fp-ts/function';
import * as Eq from 'fp-ts/Eq';
import * as O from 'fp-ts/Option';
import * as E from 'fp-ts/Either';
import * as A from 'fp-ts/Array';

import { BackendProp, BackendPropLiterals, FontSize, fontSizeList, Size } from '../../types/editor';
import { LabelTemplateType, LabelTemplateTypeOption } from '../../types/labels';

import { Protocol } from '../../api/protocol';
import { useNoty } from '../../hooks/noty';
import { Path } from '../../router';
import {
    exampleBackendBarcodeMetadata,
    backendBarcodeProp,
    backendVariableProps,
    exampleBackendPropsMetadata,
    exampleImageMetadata,
    exampleTextMetadata,
    backendNumberOfBoxesProp,
    exampleBackendQRCodeMetadata,
    backendQRCodeProp,
} from './mock';

import { makeImageFromEditorWorkspace, processFirstFileTE } from '../../utils/reader';
import { useWorkspaceEvent, WorkspaceEvents } from '../../utils/workspace';
import { makeLabelTemplateType } from '../../utils/brands';
import { labelTemplateTypeMap } from '../../utils/const';
import { approximateTextSize } from '../../utils/strings';
import { delay } from '../../utils/time';

import {
    createLabelTemplateFx,
    getLabelTemplateDetailsFx,
    updateLabelTemplateFx,
} from '../../store/templates';

import { EditorWorkspace } from '../../components/EditorWorkspace/EditorWorkspace';
import { EditorControls } from '../../components/EditorControls/EditorControls';
import { CommonLayout } from '../../layouts/CommonLayout/CommonLayout';

import { BackendPropNode } from '../../components/EditorNodes/BackendPropNode';
import { ImageNode } from '../../components/EditorNodes/ImageNode';
import { TextNode } from '../../components/EditorNodes/TextNode';
import {
    AbstractEditorNode,
    EditorNodeBackendPropMetadata,
    EditorNodeMetadata,
    EditorNodePosition,
} from '../../components/EditorNodes/interface/node.interface';

import { useQuery } from '../../hooks/router';

import styles from './LabelEditor.module.scss';

const typeList: LabelTemplateTypeOption[] = [
    { value: 'meat', label: labelTemplateTypeMap.meat },
    { value: 'normal', label: labelTemplateTypeMap.normal },
    { value: 'transport_package', label: labelTemplateTypeMap.transport_package },
];

// const aspectRatio = { width: 1.8, height: 1 };
const barcodeSize: Size = { width: 831, height: 236 };
const qrCodeSize: Size = { width: 236, height: 236 };
// const canvasSize = 600;
const width = 1439;
const height = 833;
const center: EditorNodePosition = { x: width / 2, y: height / 2 };
const backendMockNodes: EditorNodeMetadata[] = [
    exampleTextMetadata,
    exampleImageMetadata,
    exampleBackendPropsMetadata,
    exampleBackendBarcodeMetadata,
    exampleBackendQRCodeMetadata,
];

const dehydrateMap = {
    text: TextNode,
    image: ImageNode,
    backendProp: BackendPropNode,
};

type WrappedEditorNode = {
    readonly InstanceRef: RefObject<AbstractEditorNode>;
    readonly Component: JSX.Element;
};

const componentEq = pipe(
    Eq.eqStrict,
    Eq.contramap((element: JSX.Element) => element.key)
);

const foldBackedPropsKindFromWrappersArray = flow(
    A.map(({ InstanceRef: { current } }: WrappedEditorNode) => current),
    A.filter((instance): instance is BackendPropNode => instance instanceof BackendPropNode),
    A.map((instance) => instance.props.prop.kind)
);

const metaBackendPropLens = Lens.fromPath<EditorNodeBackendPropMetadata>()(['content', 'prop']);

// Compare older version of backend props and actualize node data
const regenerateMeta = (meta: EditorNodeMetadata): EditorNodeMetadata => {
    if (meta.kind !== 'backendProp') return meta;

    const prop = metaBackendPropLens.get(meta);

    if (prop.kind === 'barcode' || prop.kind === 'qr_code') return meta;

    return pipe(
        backendVariableProps,
        A.findFirst((item) => item.name === prop.name),
        O.fold(
            () => meta,
            (actual) => pipe(meta, metaBackendPropLens.set(actual))
        )
    );
};

const openPrintWindowWithImage = (base64Image: string, templateName: string): void => {
    const printWindow = window.open('', templateName);
    if (printWindow !== null) {
        printWindow.document.write(
            `<html lang="ru">
                <head>
                    <style>
                        @page { size: landscape; margin: 0;}
                    </style>
                    <title>${templateName}</title>
                </head>
                <body>
                    <img src="${base64Image}" width="972" alt="${templateName}">
                </body>
                <script>
                    setTimeout(() => {
                        window.print();
                    }, 20);
                </script>
            </html>`
        );
        printWindow.open();
        printWindow.focus();
    }
};

enum EditorMode {
    Create = 'create',
    Edit = 'edit',
}

export const LabelEditor: FunctionComponent = () => {
    const workspaceRef = createRef<HTMLDivElement>();
    const fileInputRef = createRef<HTMLInputElement>();
    const typeInputRef = createRef<HTMLInputElement>();
    const templateNameRef = createRef<HTMLInputElement>();

    const [currentFontSize, setCurrentFontSize] = useState<FontSize>(16);
    const [isPreviewEnabled, setPreviewState] = useState(false);
    const [templateName, setTemplateName] = useState<string>('');
    const [editorMode, setEditorMode] = useState(EditorMode.Create);
    const [currentType, setType] = useState<LabelTemplateType>();
    const [nodes, setNodes] = useState<WrappedEditorNode[]>([]);
    const backendProps = useMemo(() => {
        const defaultProps = [...backendVariableProps, backendBarcodeProp, backendQRCodeProp];
        if (currentType === 'transport_package') defaultProps.push(backendNumberOfBoxesProp);
        return defaultProps;
    }, [currentType]);

    const history = useHistory();
    const queryParams = useQuery();
    const { id } = useParams<{ id: string }>();
    const { handleApiError, showNoty } = useNoty();

    const isLoading = useStore(getLabelTemplateDetailsFx.pending);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onRemoveNodeHandler = (Component: JSX.Element) => {
        setNodes((state) =>
            pipe(
                state,
                A.findIndex((node: WrappedEditorNode) =>
                    componentEq.equals(node.Component, Component)
                ),
                O.chain((index) => pipe(state, A.deleteAt(index))),
                O.getOrElse(() => state)
            )
        );
    };

    const handlePrint = async () => {
        if (workspaceRef.current === null) return;

        WorkspaceEvents.emit('beforePrint');

        await delay(100);

        pipe(
            await makeImageFromEditorWorkspace(workspaceRef.current),
            E.fold(
                (e) => console.warn(e),
                ({ image }) => {
                    openPrintWindowWithImage(
                        image,
                        templateName === '' ? 'Новый шаблон этикетки' : templateName
                    );
                }
            )
        );

        WorkspaceEvents.emit('afterPrint');
    };

    const handleSave = async () => {
        if (isLoading) return;

        if (templateName === '') {
            showNoty('Введите название шаблона');
            templateNameRef.current?.focus();
            return;
        }

        if (currentType === undefined) {
            showNoty('Выберите тип этикетки');
            typeInputRef.current?.focus();
            return;
        }

        const templateData = pipe(
            nodes,
            A.filterMap((node) => O.fromNullable(node.InstanceRef.current?.hydrate()))
        );

        if (editorMode === EditorMode.Edit) {
            const payload: Protocol.UpdateLabelTemplatePayload = {
                id: Number(id),
                type: currentType,
                name: templateName,
                data: JSON.stringify(templateData),
            };

            updateLabelTemplateFx(payload)
                .then(() => {
                    showNoty('Шаблон успешно изменен', 'success');
                    history.push(Path.LabelTemplates);
                })
                .catch(handleApiError);
        } else {
            const payload: Protocol.CreateLabelTemplatePayload = {
                name: templateName,
                type: currentType,
                data: JSON.stringify(templateData),
            };

            createLabelTemplateFx(payload)
                .then(() => {
                    showNoty('Шаблон успешно создан', 'success');
                    history.push(Path.LabelTemplates);
                })
                .catch(handleApiError);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const wrapMeta = (meta: EditorNodeMetadata): WrappedEditorNode => {
        const ComponentFactory = dehydrateMap[meta.kind];
        const InstanceRef = createRef<AbstractEditorNode>();
        const Component = ComponentFactory.dehydrate(
            regenerateMeta(meta),
            InstanceRef
        )(() => onRemoveNodeHandler(Component));
        return { Component, InstanceRef };
    };

    const appendMetaToNode = (meta: EditorNodeMetadata): void =>
        setNodes((h) => [...h, wrapMeta(meta)]);

    const onAddTextHandler = () => {
        const defaultText = 'Введите текст';
        appendMetaToNode({
            kind: 'text',
            position: center,
            size: approximateTextSize(defaultText, currentFontSize),
            content: { text: defaultText, fontSize: currentFontSize },
        });
    };

    const onAddBackendProp = (prop: BackendProp): void => {
        if (prop.kind === 'barcode') {
            appendMetaToNode({
                kind: 'backendProp',
                position: {
                    x: center.x - barcodeSize.width / 2,
                    y: center.y - barcodeSize.height / 2,
                },
                size: barcodeSize,
                content: { prop, fontSize: 14 },
            });
        } else if (prop.kind === 'qr_code') {
            appendMetaToNode({
                kind: 'backendProp',
                position: {
                    x: center.x - qrCodeSize.width / 2,
                    y: center.y - qrCodeSize.height / 2,
                },
                size: qrCodeSize,
                content: { prop, fontSize: 14 },
            });
        } else {
            const blockSize = approximateTextSize(prop.schematicView, currentFontSize);
            appendMetaToNode({
                kind: 'backendProp',
                position: { x: center.x - blockSize.width / 2, y: center.y - blockSize.height / 2 },
                size: blockSize,
                content: { prop, fontSize: currentFontSize },
            });
        }
    };

    const onAddImageHandler = (): void => fileInputRef.current?.click();

    const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        e.persist();

        const firstFileData = await processFirstFileTE(e.target.files);

        pipe(
            firstFileData,
            E.fold(
                (readerError) => console.warn(readerError),
                ({ blob, size }) => {
                    const addedImgAspectRatio = size.width / size.height;

                    const newSizeOfImgNode: Size = size;
                    if (newSizeOfImgNode.width > width) {
                        newSizeOfImgNode.width = width - 200;
                        newSizeOfImgNode.height = (width - 200) / addedImgAspectRatio;
                    }
                    if (newSizeOfImgNode.height > height) {
                        newSizeOfImgNode.height = height - 200;
                        newSizeOfImgNode.width = (height - 200) * addedImgAspectRatio;
                    }
                    appendMetaToNode({
                        kind: 'image',
                        position: {
                            x: center.x - newSizeOfImgNode.width / 2,
                            y: center.y - newSizeOfImgNode.height / 2,
                        },
                        size: newSizeOfImgNode,
                        content: { blob },
                    });
                }
            )
        );
    };

    const togglePreviewHandler = (): void => {
        WorkspaceEvents.emit(isPreviewEnabled ? 'afterPrint' : 'beforePrint');
        setPreviewState(!isPreviewEnabled);
    };

    const [useBackendProps, setUsedBackendProps] = useState<BackendPropLiterals[]>([]);
    useEffect(() => {
        // Wait lifecycle next tick ref binding
        setTimeout(() => {
            setUsedBackendProps(foldBackedPropsKindFromWrappersArray(nodes));
        }, 100);
    }, [nodes]);

    useEffect(() => {
        if (id !== undefined) {
            setEditorMode(EditorMode.Edit);
            getLabelTemplateDetailsFx({ id: Number(id) })
                .then((res) => {
                    const parsedNs: EditorNodeMetadata[] = JSON.parse(res.data);
                    setType(res.type);
                    setTemplateName(res.name);
                    setNodes(parsedNs.map(wrapMeta));
                })
                .catch((e) => {
                    handleApiError(e);
                    history.push(Path.LabelTemplates);
                });
        } else {
            setNodes(backendMockNodes.map(wrapMeta));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        const queryType = makeLabelTemplateType(queryParams.get('type'));
        if (O.isSome(queryType)) setType(queryType.value);
    }, [queryParams]);

    useWorkspaceEvent('afterPrint', () => setPreviewState(false));

    return (
        <CommonLayout
            withoutContentStyles
            header={{
                heading:
                    editorMode === EditorMode.Create
                        ? 'Новый шаблон этикетки'
                        : 'Редактирование шаблона этикетки',
            }}
        >
            <div className={styles.root}>
                <EditorControls
                    fontSize={currentFontSize}
                    fontSizeList={fontSizeList}
                    backendProps={backendProps}
                    usedBackedProps={useBackendProps}
                    onFontSizeChange={setCurrentFontSize}
                    onSave={handleSave}
                    onCancel={() => history.push(Path.LabelTemplates)}
                    onPrint={handlePrint}
                    appendBackendProp={onAddBackendProp}
                    appendImage={onAddImageHandler}
                    appendText={onAddTextHandler}
                    labelNameValue={templateName}
                    onLabelNameChange={(newName) => setTemplateName(newName)}
                    templateInputRef={templateNameRef}
                    isPreviewEnabled={isPreviewEnabled}
                    onTogglePreview={togglePreviewHandler}
                    typeList={typeList}
                    currentType={currentType}
                    onChangeType={(newType) => setType(newType)}
                    typeInputRef={typeInputRef}
                />
                <EditorWorkspace
                    nodes={nodes.map((node) => node.Component)}
                    size={{ width, height }}
                    workspaceRef={workspaceRef}
                    isLoading={isLoading}
                />
            </div>
            <input
                hidden
                type="file"
                accept="image/*"
                multiple={false}
                ref={fileInputRef}
                onChange={onSelectFile}
            />
        </CommonLayout>
    );
};
