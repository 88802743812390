import React, { RefObject } from 'react';
import { nanoid } from 'nanoid';
import clsx from 'clsx';

import { ClickAwayListener } from '@material-ui/core';
import { Crop, DeleteForever, Edit, Save } from '@material-ui/icons';

import {
    AbstractEditorNode,
    DehydrateBindings,
    EditorNodeInterface,
    EditorNodeMetadata,
    EditorNodePosition,
    NodeProps,
} from './interface/node.interface';
import { FontSize, fontSizeList } from '../../types/editor';
import { StaticMeta } from '../../utils/types';

import baseStyles from './BaseNode.module.scss';
import styles from './TextNode.module.scss';

type TextNodeProps = NodeProps & {
    fontSize: FontSize;
    text: string;
};

type TextNodeState = {
    position: EditorNodePosition;
    text: string;
    fontSize: FontSize;
};

@StaticMeta<EditorNodeInterface>()
export class TextNode extends AbstractEditorNode<TextNodeProps, TextNodeState> {
    constructor(props: TextNodeProps) {
        super(props);

        const { position } = this.props;

        this.state = {
            position,
            selected: false,
            dragging: false,
            draggable: false,
            editable: false,
            resizable: false,
            size: props.size,
            text: props.text,
            fontSize: props.fontSize,
        };
    }

    static dehydrate(
        meta: EditorNodeMetadata,
        selfRef: RefObject<AbstractEditorNode>
    ): DehydrateBindings {
        if (meta.kind !== 'text') throw new TypeError('Invalid meta type, expected Text meta');

        return (onDelete) => (
            <TextNode
                ref={selfRef as RefObject<TextNode>}
                key={nanoid(6)}
                position={meta.position}
                fontSize={meta.content.fontSize}
                size={meta.size}
                text={meta.content.text}
                onDelete={onDelete}
            />
        );
    }

    hydrate = (): EditorNodeMetadata => {
        const { position, text, size, fontSize } = this.state;

        return {
            kind: 'text',
            content: { text, fontSize },
            position,
            size,
        };
    };

    onChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        e.persist();
        this.setState({
            size: {
                width: this.state.size.width,
                height: Math.max(e.currentTarget.scrollHeight + 4, this.state.size.height),
            },
        });
        this.setState({ text: e.currentTarget.value });
    };

    onEditHandler = (): void => {
        this.setState({ editable: true }, () => {
            this.setState({ selected: true, resizable: false });
            this.textAreaRef.current?.focus();
        });
    };

    onSaveHandler = (): void => {
        this.setState({ editable: false, resizable: false }, () =>
            this.setState({ selected: true })
        );
    };

    onDeleteHandler = (): void => this.props.onDelete();

    render(): JSX.Element {
        const {
            text,
            position,
            selected,
            draggable,
            editable,
            resizable,
            dragging,
            size,
            fontSize,
        } = this.state;

        return (
            <ClickAwayListener onClickAway={this.clickAwayHandler}>
                <div
                    aria-hidden="true"
                    ref={this.blockRef}
                    style={{
                        top: position.y,
                        left: position.x,
                        width: size.width,
                        height: size.height,
                        fontSize,
                    }}
                    onClick={this.toggleSelected}
                    onMouseDown={resizable ? undefined : this.mouseDownHandler}
                    className={clsx(
                        baseStyles.root,
                        styles.root,
                        selected && baseStyles.selected,
                        dragging && baseStyles.dragging,
                        resizable && baseStyles.resizable,
                        !editable && draggable && baseStyles.draggable
                    )}
                >
                    {editable ? (
                        <textarea
                            ref={this.textAreaRef}
                            onChange={this.onChangeHandler}
                            disabled={!editable}
                            defaultValue={text}
                            className={styles.input}
                        />
                    ) : (
                        <span
                            className={styles.span}
                            dangerouslySetInnerHTML={{
                                __html: text.replace(/\n/g, '<br/>'),
                            }}
                        />
                    )}

                    <div
                        className={baseStyles.menuContainer}
                        style={{
                            bottom: size.height - 1,
                            display: selected && !resizable ? 'flex' : 'none',
                        }}
                    >
                        {/* --- Font size select --- */}
                        <select
                            className={baseStyles.fontSizeSelect}
                            value={this.state.fontSize}
                            onChange={(event) => {
                                this.setState({
                                    fontSize: Number(event.currentTarget.value) as FontSize,
                                });
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {fontSizeList.map((listItem) => (
                                <option key={listItem} value={listItem}>
                                    {listItem}
                                </option>
                            ))}
                        </select>

                        {/* --- Edit toggle button --- */}
                        <button
                            type="button"
                            className={baseStyles.menuButton}
                            onClick={editable ? this.onSaveHandler : this.onEditHandler}
                        >
                            {editable ? <Save fontSize="small" /> : <Edit fontSize="small" />}
                        </button>

                        {/* --- Resize button --- */}
                        <button
                            type="button"
                            className={baseStyles.menuButton}
                            onClick={this.onResizeHandler}
                        >
                            <Crop fontSize="small" />
                        </button>

                        {/* --- Delete button --- */}
                        <button
                            type="button"
                            className={baseStyles.menuButton}
                            onClick={this.onDeleteHandler}
                        >
                            <DeleteForever fontSize="small" />
                        </button>
                    </div>

                    {resizable && (
                        <div
                            aria-hidden="true"
                            className={baseStyles.resizableArea}
                            onMouseDown={this.resizeMouseDownHandler}
                        />
                    )}
                </div>
            </ClickAwayListener>
        );
    }
}
