import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { reportWebVitals } from './reportWebVitals';

import { Root } from './Root';

import './styles/global.scss';

// Sentry initialization
const DSN = process.env.REACT_APP_SENTRY_DSN;
if (DSN !== undefined) {
    Sentry.init({ dsn: DSN });
}

ReactDOM.render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
