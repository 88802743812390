import React from 'react';
import { Drawer } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

import styles from './Sidebar.module.scss';

const links = [
    {
        url: '/label-templates',
        label: 'Шаблоны',
        icon: ViewComfyIcon,
    },
    {
        url: '/accounting',
        label: 'Учет',
        icon: PlaylistAddCheckIcon,
    },
];

export const Sidebar: React.FunctionComponent = () => {
    const { pathname } = useLocation();

    return (
        <Drawer variant="permanent" anchor="left" open className={styles.root}>
            {links.map((link) => {
                const Icon = link.icon;

                return (
                    <Link
                        className={clsx(styles.link, pathname === link.url && styles.active)}
                        to={link.url}
                    >
                        <Icon />
                        <span className={styles.label}>{link.label}</span>
                    </Link>
                );
            })}
        </Drawer>
    );
};
