import { BearerAuth } from './client/auth';
import { ApiClient } from './client';
import { Protocol } from './protocol';

export const apiClient = new ApiClient(BearerAuth());

// ----- Methods -----
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const apiLogin = (payload: Protocol.LoginPayload) =>
    apiClient.post<Protocol.LoginPayload, Protocol.LoginResult>(
        '/api/office_worker/auth/login',
        payload
    );

export const apiLogout = () =>
    apiClient.post<Protocol.LogoutPayload, Protocol.LogoutResult>('/api/office_worker/auth/logout');

export const apiGetLabelTemplates = () =>
    apiClient.get<Protocol.LabelTemplatesListPayload, Protocol.LabelTemplatesListResult>(
        '/api/office_worker/label_templates/list'
    );

export const apiAssignLabelVessels = (payload: Protocol.LabelAssignVesselsPayload) =>
    apiClient.post<Protocol.LabelAssignVesselsPayload, Protocol.LabelAssignVesselsResult>(
        '/api/office_worker/label_templates/assign_vessels',
        payload
    );

export const apiDeleteLabelTemplate = (payload: Protocol.DeleteLabelTemplatePayload) =>
    apiClient.post<Protocol.DeleteLabelTemplatePayload, Protocol.DeleteLabelTemplateResult>(
        '/api/office_worker/label_templates/delete',
        payload
    );

export const apiCreateLabelTemplate = (payload: Protocol.CreateLabelTemplatePayload) =>
    apiClient.post<Protocol.CreateLabelTemplatePayload, Protocol.CreateLabelTemplateResult>(
        '/api/office_worker/label_templates/create',
        payload
    );

export const apiGetLabelTemplateDetails = (payload: Protocol.GetLabelTemplatePayload) =>
    apiClient.get<Protocol.GetLabelTemplatePayload, Protocol.GetLabelTemplateResult>(
        '/api/office_worker/label_templates/get',
        payload
    );

export const apiUpdateLabelTemplate = (payload: Protocol.UpdateLabelTemplatePayload) =>
    apiClient.post<Protocol.UpdateLabelTemplatePayload, Protocol.UpdateLabelTemplateResult>(
        '/api/office_worker/label_templates/update',
        payload
    );

export const apiCopyLabelTemplate = (payload: Protocol.CopyLabelTemplatePayload) =>
    apiClient.post<Protocol.CopyLabelTemplatePayload, Protocol.CopyLabelTemplateResult>(
        '/api/office_worker/label_templates/copy',
        payload
    );

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
