import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { useHistory } from 'react-router-dom';
import { AppBar, Button, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { HeaderProps } from '../../types/layout';
import { logoutFx } from '../../store/app';
import { AlertDialog } from '../AlertDialog/AlertDialog';
import { useNoty } from '../../hooks/noty';
import { Path } from '../../router';

import styles from './AppHeader.module.scss';

export const AppHeader: React.FunctionComponent<HeaderProps> = (props) => {
    const { heading, button } = props;
    const [isLogoutDialogOpen, setLogoutDialogOpen] = useState(false);
    const { handleApiError } = useNoty();
    const isLogoutLoading = useStore(logoutFx.pending);
    const history = useHistory();

    const handleLogout = () => {
        logoutFx().catch(handleApiError);
        history.push(Path.Login);
    };

    return (
        <AppBar position="sticky" className={styles.root}>
            <Typography className={styles.heading} variant="h1">
                {heading}
            </Typography>

            <div className={styles.buttonsGroup}>
                {button && (
                    <Button
                        variant="contained"
                        className={styles.button}
                        color="primary"
                        onClick={button.action}
                        startIcon={<AddIcon />}
                    >
                        {button.text}
                    </Button>
                )}

                <IconButton
                    onClick={() => {
                        setLogoutDialogOpen(true);
                    }}
                >
                    <ExitToAppIcon />
                </IconButton>
            </div>

            <AlertDialog
                isOpen={isLogoutDialogOpen}
                isLoading={isLogoutLoading}
                title="Выйти из приложения?"
                content="Вы будете перенаправлены на форму входа"
                confirmCaption="Выйти"
                cancelCaption="Отмена"
                onClose={() => {
                    setLogoutDialogOpen(false);
                }}
                onConfirm={handleLogout}
            />
        </AppBar>
    );
};
