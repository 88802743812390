import { createDomain } from 'effector-logger/macro';

import { pipe } from 'fp-ts/function';
import * as Ord from 'fp-ts/Ord';
import * as A from 'fp-ts/Array';

import { LabelTemplate, Vessel } from '../types/labels';
import {
    apiAssignLabelVessels,
    apiCopyLabelTemplate,
    apiCreateLabelTemplate,
    apiDeleteLabelTemplate,
    apiGetLabelTemplateDetails,
    apiGetLabelTemplates,
    apiUpdateLabelTemplate,
} from '../api';
import { ApiError } from '../api/client/errors';
import { Protocol } from '../api/protocol';

const templatesDomain = createDomain();

type LabelTemplatesListState = {
    items: LabelTemplate[];
    vessels: Vessel[];
    isFetched: boolean;
};

// --- Effects ---
export const fetchTemplateListFx = templatesDomain
    .createEffect<Protocol.LabelTemplatesListPayload, Protocol.LabelTemplatesListResult, ApiError>()
    .use(apiGetLabelTemplates);

export const assignTemplatesToVesselsFx = templatesDomain
    .createEffect<Protocol.LabelAssignVesselsPayload, Protocol.LabelAssignVesselsResult, ApiError>()
    .use(apiAssignLabelVessels);

export const deleteLabelTemplateFx = templatesDomain
    .createEffect<
        Protocol.DeleteLabelTemplatePayload,
        Protocol.DeleteLabelTemplateResult,
        ApiError
    >()
    .use(apiDeleteLabelTemplate);

export const createLabelTemplateFx = templatesDomain
    .createEffect<
        Protocol.CreateLabelTemplatePayload,
        Protocol.CreateLabelTemplateResult,
        ApiError
    >()
    .use(apiCreateLabelTemplate);

export const updateLabelTemplateFx = templatesDomain
    .createEffect<
        Protocol.UpdateLabelTemplatePayload,
        Protocol.UpdateLabelTemplateResult,
        ApiError
    >()
    .use(apiUpdateLabelTemplate);

export const copyLabelTemplateFx = templatesDomain
    .createEffect<Protocol.CopyLabelTemplatePayload, Protocol.CopyLabelTemplateResult, ApiError>()
    .use(apiCopyLabelTemplate);

export const getLabelTemplateDetailsFx = templatesDomain
    .createEffect<Protocol.GetLabelTemplatePayload, Protocol.GetLabelTemplateResult, ApiError>()
    .use(apiGetLabelTemplateDetails);

// --- Store ---
const initialLabelTemplatesListState: LabelTemplatesListState = {
    items: [],
    vessels: [],
    isFetched: false,
};

const ordNumberDesc = Ord.getDualOrd(Ord.ordNumber);

const sortVessels = pipe(
    ordNumberDesc,
    Ord.contramap((vessel: Vessel) => vessel.id),
    A.sort
);

// TODO: Move repeateble dectructurization
export const $labelTemplates = templatesDomain
    .createStore(initialLabelTemplatesListState)
    .on(deleteLabelTemplateFx.doneData, (state, { vessels, items }) => ({
        ...state,
        vessels: sortVessels(vessels),
        items,
    }))
    .on(assignTemplatesToVesselsFx.doneData, (state, { vessels, items }) => ({
        ...state,
        vessels: sortVessels(vessels),
        items,
    }))
    .on(fetchTemplateListFx.doneData, (state, { vessels, items }) => ({
        ...state,
        vessels: sortVessels(vessels),
        items,
        isFetched: true,
    }))
    .on([createLabelTemplateFx.doneData, copyLabelTemplateFx.doneData], (state, result) => {
        const newItems = [...state.items, result];
        return { ...state, items: newItems };
    });
