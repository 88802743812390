import { createDomain } from 'effector';
import { persist } from 'effector-storage/local/fp';

import { ApiError } from '../api/client/errors';
import { Protocol } from '../api/protocol';
import { apiClient, apiLogin, apiLogout } from '../api';
import { Token } from '../api/client/auth';
import { User, UserRole } from '../types/common';

const AppDomain = createDomain();

// this hook will persist every store, created in domain,
// in `localStorage`, using stores' names as keys
AppDomain.onCreateStore(persist());

// --- Events ---
export const clearSession = AppDomain.createEvent();

// --- Effects ---
export const loginFx = AppDomain.createEffect<
    Protocol.LoginPayload,
    Protocol.LoginResult,
    ApiError
>().use(apiLogin);

export const logoutFx = AppDomain.createEffect<
    Protocol.LogoutPayload,
    Protocol.LogoutResult,
    ApiError
>().use(apiLogout);

apiClient.setInvalidTokenHandler(() => clearSession());

// --- App store ---
type AppState = {
    isLoggedIn: boolean;
    token: Token;
    user: User;
};

const appInitialState: AppState = {
    isLoggedIn: false,
    token: null,
    user: {
        id: -1,
        login: '',
        role: UserRole.OfficeWorker,
    },
};

export const $app = AppDomain.createStore<AppState>(appInitialState, { name: 'app' })
    .on(loginFx.doneData, (state, { user, token }) => ({ ...state, isLoggedIn: true, user, token }))
    .reset(clearSession, logoutFx.done);
