import { none, Option, some } from 'fp-ts/Option';

import { labelTemplateTypeMap } from './const';
import { LabelTemplateType } from '../types/labels';

const validTemplateTypes = Object.keys(labelTemplateTypeMap);
export const isLabelTemplateType = (value: unknown): value is LabelTemplateType =>
    typeof value === 'string' && validTemplateTypes.includes(value);

export const makeLabelTemplateType = (value: unknown): Option<LabelTemplateType> =>
    isLabelTemplateType(value) ? some(value) : none;
