import React, { FunctionComponent } from 'react';
import logoPath from '../../assets/logo.svg';
import styles from './FeaturedLayout.module.scss';

export const FeaturedLayout: FunctionComponent = ({ children }) => (
    <section className={styles.root}>
        <div className={styles.content}>
            <img className={styles.logo} src={logoPath} alt="" />
            {children}
        </div>
    </section>
);
