import { useSnackbar, VariantType } from 'notistack';
import { useCallback } from 'react';
import { ApiError } from '../api/client/errors';

type ApiErrorCallback = (apiError: ApiError) => void;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNoty = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const showNoty = useCallback(
        (message: string, variant: VariantType = 'error') =>
            enqueueSnackbar(message, {
                variant,
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
            }),
        [enqueueSnackbar]
    );

    const handleApiError = useCallback(
        (error: unknown, callback: ApiErrorCallback = () => {}): void => {
            if (error instanceof ApiError) {
                const commonErrorMessage = error.getCommonFirstMessage();
                if (commonErrorMessage) showNoty(commonErrorMessage);
                else {
                    const invalidFirstFieldMessage = error.getInvalidFirstMessage();
                    if (invalidFirstFieldMessage) showNoty(invalidFirstFieldMessage);
                }
                callback(error);
            }
        },
        [showNoty]
    );

    const closeNoty = closeSnackbar;

    return {
        showNoty,
        closeNoty,
        handleApiError,
    };
};
