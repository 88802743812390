export enum UserRole {
    Administrator = 'administrator',
    OfficeWorker = 'office_worker',
    ProductionManager = 'production_manager',
}

export type User = {
    id: number;
    login: string;
    role: UserRole;
};
